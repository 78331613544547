import React, { Component } from "react";
import { graphql } from 'gatsby'
import { Blocks } from 'components/Blocks';

import Layout from "components/layout";
import SEO from "components/seo";


class Page extends Component {

    render() {
      const payload = this.props.data.contentfulPage
      const shopify = this.props.pageContext.shopify
      
      return ( 
        <Layout>
          <SEO data={payload.seo} />
          <Blocks data={payload.blocks} shopify={shopify} />
        </Layout>
      )
    }
}

export default Page


// language=GraphQL
export const pageQuery = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      seo {
        ...SEOFields
      }
      blocks { 
        ...BlockFields
      }
    }
  }
`;